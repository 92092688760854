<app-intro title="Les actualités"></app-intro>
<div class="container-fluid  bg-grey py-5">
  <div class="container">
    <div class="text-center my-lg-0 my-3">
      <h2 class="text-cormorant">S'informer ? </h2>
      <p class="my-5">
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
      </p>
    </div>
  </div>
</div>
<div class="container">
  <app-contact-shortcut class="margin-negative"></app-contact-shortcut>
  <div class="row text-center text-lg-start mt-2">
    <div class="my-3">
      <div class="card-container">
        <ng-container *ngFor="let actu of actualites | paginate: { itemsPerPage: 12, currentPage: p }">
          <app-news class="border actualite-card" [actualite]="actu"></app-news>
        </ng-container>
      </div>
      <div class="text-center my-3">
        <pagination-controls class="custom" previousLabel="précédent" nextLabel="suivant" (pageChange)="p = $event"></pagination-controls>
      </div>
    </div>
  </div>
  <div class="text-center my-3">
    <a href="#" class="btn btn-yellow text-white">VOIR PLUS <i class="ms-3 fa-solid fa-arrow-right text-white"></i></a>
  </div>
</div>
