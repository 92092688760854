import { AppComponent } from "./app.component";
import { HomeOfferComponent } from "./components/home-offer/home-offer.component";
import { IntroComponent } from "./components/intro/intro.component";
import { NewsComponent } from "./components/news/news.component";
import { HomeComponent } from "./pages/home/home.component";
import { ServicesComponent } from "./pages/services/services.component";
import { NewsComponent as NewsPages } from './pages/news/news.component';
import { ContactShortcutComponent } from "./components/contact-shortcut/contact-shortcut.component";
import { PriceComponent } from "./pages/price/price.component";
import { ContactComponent } from "./structure/contact/contact.component";
import { ShopComponent } from "./pages/shop/shop.component";
import { NewsDetailComponent } from "./pages/news-detail/news-detail.component";
import { OfferDetailComponent } from "./pages/offer-detail/offer-detail.component";
import { PageNotFoundComponent } from "./pages/page-not-found/page-not-found.component";
import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppRoutingModule } from "./app-routing.module";
import { GoogleMapsModule } from "@angular/google-maps";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { HttpClientModule } from "@angular/common/http";
import { NgxPaginationModule } from "ngx-pagination";
import { FormsModule } from "@angular/forms";
import { DpeGesComponent } from './pages/offer-detail/dpe-ges/dpe-ges.component';



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HomeOfferComponent,
    NewsComponent,
    ServicesComponent,
    IntroComponent,
    NewsPages,
    ContactShortcutComponent,
    PriceComponent,
    ContactComponent,
    ShopComponent,
    NewsDetailComponent,
    OfferDetailComponent,
    PageNotFoundComponent,
    DpeGesComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    NgbModule,
    AppRoutingModule,
    GoogleMapsModule,
    SlickCarouselModule,
    HttpClientModule,
    NgxPaginationModule,
    FormsModule
  ],
  providers: [
    // authInterceptorProviders
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
