<app-intro title="Nos tarifs"></app-intro>
<div class="container-fluid text-lg-start text-center">
  <div class="row">
    <div class="col-lg-6 about-left  p-5">
      <h2 class="mb-4">TARIF DES EMOLUMENTS DE NEGOCIATION</h2>

      <p class="my-5">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy</p>
      <p><i class="fa-solid fa-check text-black"></i> Droit de la famille</p>
      <p><i class="fa-solid fa-check  text-black"></i> Affaire et des sociétés</p>
      <p class="mb-4"><i class="fa-solid fa-check  text-black"></i> Droit immobilier et de la fiscalité</p>
    </div>
    <div class="col-lg-6 about-right">
    </div>
  </div>
</div>
<div class="container my-3">
  <app-contact-shortcut></app-contact-shortcut>
</div>
