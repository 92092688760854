<div class="container-fluid intro ">
  <div class="row d-flex ">
    <div class="intro-left col-lg-5">
    </div>
    <div class="intro-right col-lg">
      <div class="carousel-container">
        <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-indicators" *ngIf="images.length > 1">
            <button *ngFor="let img of images let i = index; let first = first" type="button"
                    data-bs-target="#carouselExampleControls" [attr.aria-label]="'Slide ' + (i+1)"
                    [attr.data-bs-slide-to]="i"
                    [ngClass]="{ active: first }"></button>
          </div>
          <div class="carousel-inner" *ngIf="arrayLength !== 0">
            <div class="carousel-item"
                 *ngFor="let img of images let i = index; let first = first"
                 [ngClass]="{ active: first }">
              <img src="{{ img.file }}" class="d-block w-100">
            </div>
          </div>

          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls"
                  data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls"
                  data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="container intro-overlay pb-5 text-center text-lg-start">
    <div class="row pt-5">
      <h1 class="my-4 px-0 text-lg-start text-center">Office Notarial du <br>Ponant Pacé</h1>
      <div class="my-3">
        <p>MAÎTRE CATHERINE GUICHARD </p>
        <p> MAITRE JOSSELIN NAUT </p>
        <p>MAÎTRE GUY MESSAGER</p>
        <div class="my-3 divider"></div>
        <p> 02 99 60 61 08</p>
      </div>
      <div class="text-lg-start text-center">
        <a class="btn btn-outline-light mx-lg-auto" href="#">EN SAVOIR PLUS <i class="fa-solid fa-arrow-right"></i></a>
      </div>
    </div>
  </div>
</div>

<div class="container home-container  ps-5 pt-4 pe-5">
  <div class="row d-flex ">
    <div class="col-lg-4 bg-purple rounded">
      <form class="py-3 px-3 " (ngSubmit)="onFormSubmit(findBien.value)" #findBien="ngForm">
        <p class="text-white">Vous rechercher un bien à vendre ou en location</p>
        <div class="form-group my-3">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="achat" [(ngModel)]="achat" name="achat">
            <label class="form-check-label text-white " for="inlineRadio1">ACHETER</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="location" [(ngModel)]="achat" name="achat">
            <label class="form-check-label text-white" for="inlineRadio2">LOUER</label>
          </div>
        </div>
        <div class="form-group my-3">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" name="radioNoLabel" id="radioNoLabel1"
                   aria-label="..." [(ngModel)]="around" name="around">
            <label class="form-check-label text-white"> COMMUNES ALENTOURS</label>
          </div>
        </div>
        <div class="form-group my-5">
          <select class="form-select" aria-label="Default select example" [(ngModel)]="type" name="type">
            <option selected>Type de biens</option>
            <option *ngFor="let type of typeBien" value="{{type.id}}">{{type.nom}}</option>
          </select>
        </div>
        <div class="form-group mb-5">
          <select class="form-select" aria-label="Default select example" [(ngModel)]="commune" name="commune">
            <option selected>Communes</option>
            <option *ngFor="let city of communes" value="{{city}}">{{city}}</option>
          </select>
        </div>
        <div class="form-group">
          <button type="submit" class="btn btn-yellow d-block w-100">Rechercher</button>
        </div>
      </form>
    </div>
    <div class="col-lg-8 my-lg-0 my-3 pe-0">
      <ngx-slick-carousel class="carousel"
                          #slickModal="slick-carousel"
                          [config]="slideConfig">
        <div ngxSlickItem *ngFor="let slide of biens" class="slide">
          <div class=" mb-2 border-shadow">
            <app-home-offer [bien]="slide"></app-home-offer>
          </div>
        </div>
      </ngx-slick-carousel>
      <div class="ps-3 text-center text-lg-start my-3 my-lg-0">
        <a class="prev" (click)="prev()">
          <i class="fa-solid fa-arrow-left fa-2x text-yellow"></i>
        </a>
        <a class="prev ms-3" (click)="next()">
          <i class="fa-solid fa-arrow-right fa-2x text-yellow"></i>
        </a>
      </div>
    </div>
  </div>

<app-contact-shortcut></app-contact-shortcut>
</div>

<div class="container-fluid text-lg-start text-center mt-3">
  <div class="row">
    <div class="col-lg-6 about-left">
      <h3 class="mb-4">L'OFFICE NOTARIAL DU PONANT PACÉ</h3>
      <p>L'office notarial est situé à Pacé, Ille et Vilaine, près de Rennes. Située au nord ouest de l'agglomération
        rennaise et intégrée à Rennes Métropole Accessible par la voie express RENNES-SAINT BRIEUC (RN12) et à proximité
        immédiate de Rennes </p>
      <p class="my-5">L'office Notarial du Ponant Pacé est l'étude historique de Pacé depuis plus d'un siècle. Le recours à une étude
        notariale s'avère naturel dans le cadre de la gestion de votre patrimoine, en cet instant décisif que constitue
        la vente, l'acquisition, la transmission ou la location d'un bien immobilier. Les compétences du notaire dans
        les domaines :</p>
      <p><i class="fa-solid fa-check"></i> Droit de la famille</p>
      <p><i class="fa-solid fa-check my-3"></i> Affaire et des sociétés</p>
      <p class="mb-4"><i class="fa-solid fa-check"></i> Droit immobilier et de la fiscalité</p>
      <a href="/services" class="btn btn-yellow text-white">EN SAVOIR PLUS <i class="ms-3 fa-solid fa-arrow-right text-white"></i></a>
    </div>
    <div class="col-lg-6 about-right">
    </div>
  </div>
</div>

<div class="container my-4">
    <div class="d-lg-flex">
      <div class="border px-0 mx-lg-3 my-lg-0" *ngFor="let actu of actualites |slice:0:3">
        <app-news [actualite]="actu"></app-news>
      </div>
      <!-- <div class="border px-0 mx-lg-3 my-3 my-lg-0">
        <app-news></app-news>
      </div>
      <div class="border px-0 mx-lg-3 my-lg-0">
        <app-news></app-news>
      </div> -->
    </div>
  <div class="text-center mt-3">
    <a href="#" class="btn btn-yellow"> TOUTES NOS ACTUALITÉS  <i class="ms-3 fa-solid fa-arrow-right text-white"></i></a>
  </div>
</div>
