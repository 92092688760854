<header class="text-center text-lg-start">
  <div class="top-bar">
    <div class="container">
      <div class="row d-lg-flex align-content-between py-3">
        <div class="col-lg col-12">
           <div class="d-lg-flex">
             <a class="me-5" href="#location">
               <i class="fa fa-duotone fa-map-pin"></i>
               14 boulevard Dumaine de la josserie - 35740 PACE
             </a>
             <a href="tel:0299606108">
               <i class="fa-solid fa-phone"></i>
               02 99 60 61 08
             </a>
           </div>
        </div>
        <div class="col-lg-1 col-12 text-lg-end text-center">
          <a href="#"><i class="fa-brands fa-facebook-f"></i></a>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <nav class="navbar navbar-expand-lg navbar-light">
      <a class="navbar-brand me-5" href="#">
        <img href="https://www.office-notarial-pace.com/" src="/assets/images/logo.jpg" class="img-fluid"   height="60" width="120">
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navBar" aria-controls="navBar" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse ms-lg-5" id="navBar" [ngbCollapse]="isCollapsed">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item" [class.active]="router.url==='/'">
            <a class="nav-link" href="/">ACCUEIL</a>
          </li>
          <li class="nav-item" [class.active]="router.url==='/services'">
            <a class="nav-link" href="/services">SERVICES</a>
          </li>
          <li class="nav-item" [class.active]="router.url==='/actualites'">
            <a class="nav-link" href="/actualites" >ACTUALITÉS</a>
          </li>

          <li class="nav-item" [class.active]="router.url==='/tarifs'">
            <a class="nav-link" href="/tarifs">TARIFS</a>
          </li>
          <li class="nav-item" [class.active]="router.url==='/contact'">
            <a class="nav-link" href="/contact">CONTACTEZ-NOUS</a>
          </li>

        </ul>
        <div class="ms-auto">
          <a href="/offres"  class="btn btn-dark-purple"> ACHETER OU LOUER</a>
        </div>
      </div>
    </nav>
  </div>
</header>
<router-outlet></router-outlet>
<footer>
  <div class="text-center py-5">
    <h4 class="text-white pt-5">NOTRE EXPERTISE</h4>
    <p class="text-white my-5">Les compétences du notaire dans les domaines du droit de la famille, des affaires et des sociétés, du droit immobilier et de la fiscalité font ainsi du notaire le conseiller incontournable de la gestion de votre patrimoine.</p>
    <div  class="container">
      <div  class="row">
        <div class="col-lg-3 border-end  col-6">
          <img class="img-fluid" src="../assets/images/famille.svg">
          <p class="text-white mt-3">Famille</p>
        </div>
        <div class="col-lg-3 border-end border-md-none col-6">
          <img class="img-fluid" src="../assets/images/societe.svg">
          <p class="text-white  mt-3">Société</p>
        </div>
        <div class="col-lg-3 border-end col-6 mt-lg-0 mt-3">
          <img class="img-fluid" src="../assets/images/immobilier.svg">
          <p class="text-white  mt-3">Immobilier</p>
        </div>
        <div class="col-lg-3 col-6 mt-lg-0 mt-3">
          <img class="img-fluid" src="../assets/images/fiscalite.svg">
          <p class="text-white  mt-3">Fiscalité</p>
        </div>
      </div>
    </div>
  </div>
  <div class="garenties py-4 text-center text-lg-start">
    <div class="container">
      <div class="d-lg-flex justify-content-between align-items-center">
        <div>
          <p class="text-white">Seul votre notaire peut vous garantir cette sécurité juridique <br> qui rend vos projets plus sûrs.</p>
        </div>
        <div>
          <a href="#" class="btn btn-yellow my-3 my-lg-0"> CONTACTEZ-NOUS </a>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-6 ps-0">
        <google-map class="maps" [options]="{
          center: {lat: 48.145718885656294, lng: -1.770504863829866},
          zoom: 18}"
          width="100%"
                    height="100%"
        >
          <map-marker
            [position]="{lat: 48.14569383022985, lng: -1.7704887705767514}"
          >
          </map-marker>
        </google-map>
      </div>
      <div class="col-lg-6 text-lg-start text-center py-3" id="location">
        <p class="text-white contact ">CONTACTEZ-NOUS</p>
        <p class="my-4 text-white">Ouverture du lundi au vendredi de 9h00 à 12h00 et de 14h00 à 18h00 sauf le vendredi 17h00</p>
        <p class="text-white">
          <i class="fa fa-duotone fa-map-pin text-yellow"></i>
          14 boulevard Dumaine de la josserie - 35740 PACE
        </p>
        <a href="tel:0299606108" class="text-white my-4">
          <i class="fa-solid fa-phone text-yellow"></i>
          02 99 60 61 08
        </a>
        <a href="mailto:scp-guichard.naut.35138@notaires.fr" class="text-white">
          <i class="fa-solid fa-envelope text-yellow"></i>
          scp-guichard.naut.35138@notaires.fr
        </a>
        <img class="img-fluid my-4" src="assets/images/etude-notaire-pace-bus.png" alt="etude-notaire-pace-bus">
        <p class="text-white">
          <i class="fa-solid fa-bus text-yellow"></i>
          Carré Dumaine / 65 - 77
        </p>
        <p class="text-white mt-4">
          <i class="fa-solid fa-square-parking text-yellow"></i>
          Parking gratuit - 150 places
        </p>
      </div>
    </div>
  </div>
  <div class="footer-menu text-center text-lg-start">
    <div class="container py-5">
      <div class="row">
        <div class="col-lg-3">
          <img src="/assets/images/logo.jpg" class="img-fluid">
        </div>
        <div class="col-lg-3  mt-3 mt-lg-0">
          <p class="mb-3 text-white">NOTRE EXPERTISE</p>
          <a  href="/services" class="text-white text-decoration-none d-block">
            <i class="fa-solid fa-chevron-right text-yellow"></i>
            Du droit de la famille
          </a>
          <a  href="/services" class="text-white text-decoration-none d-block">
            <i class="fa-solid fa-chevron-right text-yellow"></i>
            Gestion du patrimoine
          </a>
          <a href="/services"  class="text-white text-decoration-none d-block">
            <i class="fa-solid fa-chevron-right text-yellow"></i>
            Des affaires et des sociétés
          </a>
          <a  href="/services" class="text-white text-decoration-none d-block">
            <i class="fa-solid fa-chevron-right text-yellow"></i>
            Du droit immobilier et de la fiscalité
          </a>
        </div>
        <div class="col-lg-3 mt-3 mt-lg-0">
          <p class="mb-3 text-white">NAVIGATION</p>
          <ul>
            <li><a href="https://www.office-notarial-pace.com/">Accueil</a></li>
            <li><a href="https://www.office-notarial-pace.com/">L'office Notarial de pacé</a></li>
            <li><a href="/offres">Acheter ou louer</a></li>
            <li><a href="/services">Services</a></li>
            <li><a href="/tarifs">Tarifs</a></li>
          </ul>
        </div>
        <div class="col-lg-3">
          <p class="mb-3 text-white"></p>
          <p class="text-white my-3">
            Maître Catherine GUICHARD<br>
            Maitre Josselin NAUT<br>
            Maître Guy MESSAGER </p>
          <p class="text-white">SAS GUICHARD-NAUT 14 boulevard Dumaine de la Josserie - 35740 PACE </p>
          <p class="text-white">Tél. 02 99 60 61 08 </p>
        </div>

      </div>
    </div>
    <div class="text-center py-3">
      <p class="text-white">Agence 11H10 Web & Com - <a href="#" class="text-decoration-none text-white">Mentions légales</a></p>
    </div>
  </div>
</footer>
