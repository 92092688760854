import { NgModule } from '@angular/core';
import { ContactComponent } from './structure/contact/contact.component';
import { HomeComponent } from './pages/home/home.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { RouterModule, Routes } from '@angular/router';
import { ServicesComponent } from './pages/services/services.component';
import { NewsComponent } from './pages/news/news.component';
import { PriceComponent } from './pages/price/price.component';
import { ShopComponent } from './pages/shop/shop.component';
import { OfferDetailComponent } from './pages/offer-detail/offer-detail.component';
import { NewsDetailComponent } from './pages/news-detail/news-detail.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'services', component: ServicesComponent },
  { path: 'actualites', component: NewsComponent },
  { path: 'tarifs', component: PriceComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'offres', component: ShopComponent },
  { path: 'offres/:slug', component: OfferDetailComponent },
  { path: 'actualites/:slug', component: NewsDetailComponent },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
