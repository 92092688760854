import { Component, Input, OnInit } from '@angular/core';
import { ActualiteService } from 'src/app/_services/actualite.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
  @Input() actualite: any;
  actualites: any;
  constructor(private actualiteService: ActualiteService) { }

  ngOnInit(): void {
    console.log(this.actualite.urlImage);

  }
}
